import { RouterModule } from '@angular/router';
import { ChangeDetectionStrategy, Component, } from '@angular/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'layout-main',
  standalone: true,
  imports: [
    CommonModule, RouterModule, 
  ],
  styles: [
`:host {
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    items: center;
}`],
  template: `

  <router-outlet></router-outlet>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayout {
  
}
